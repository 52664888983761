<template>
  <div
    class="news-views max-w-7xl mx-auto grid gap-6 lg:grid-cols-3 lg:min-h-screen"
  >
    <div class="lg:col-span-2 flex flex-col">
      <NewsShow class="flex-grow" />
    </div>
    <div class="lg:col-span-1 lg:self-start">
      <ListaNoticias title="Outras Notícias" />
    </div>
  </div>
</template>

<script>
import NewsShow from "@/components/News/NewsShow";
import ListaNoticias from "../components/Home/ListaNoticias.vue";

export default {
  components: {
    NewsShow,
    ListaNoticias,
  },
};
</script>
